import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Container, Stack, Typography, Grid, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, serverTimestamp, Timestamp, getDocs, collection  } from "firebase/firestore";
import { getDatabase, ref as dbref, runTransaction, set, child, get, update } from "firebase/database";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';

const firebaseConfig = {
  apiKey: "AIzaSyBF2TSzLFudG6zcXXcww4_w-R773324PmU",
  authDomain: "avantidigix.firebaseapp.com",
  databaseURL: "https://avantidigix-default-rtdb.firebaseio.com",
  projectId: "avantidigix",
  storageBucket: "avantidigix.appspot.com",
  messagingSenderId: "633659017175",
  appId: "1:633659017175:web:f6ce68307555e139532d92"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const rtdb = getDatabase(app);

// ----------------------------------------------------------------------

export default function SearchRegistration() {

  const [loading, setLoading] = React.useState(false);

  const [emailError, setEmailError] = React.useState(false);
  const [imeiError, setImeiError] = React.useState(false);

  const [regId, setRegId] = React.useState('');
  const [regCountry, setRegCountry] = React.useState('');
  const [regfullname, setRegfullname] = React.useState('');
  const [regStatus, setRegStatus] = React.useState('');
  const [regEWExpiredDate, setRegEWExpiredDate] = React.useState('');
  const [regEWCoverDate, setRegEWCoverDate] = React.useState('');
  const [regSCExpiredDate, setRegSCExpiredDate] = React.useState('');
  const [regSCCoverDate, setRegSCCoverDate] = React.useState('');

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate('/home')
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let emailPass = false;

    const data = new FormData(event.currentTarget);

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if(data.get('email').length > 0 && emailRegex.test(data.get('email'))){
      setEmailError(false)
      emailPass = true;
    }
    else
    {
      setEmailError(true)
    }


    if(emailPass === true ){
      
      setLoading(true)

      const arr = [];
      const allemails = [];
      let emailFound = false;
      let emailRecord = null;


      get(child(dbref(rtdb), `waitinglist`)).then((snapshot) => {
        if (snapshot.exists()) {

          
          snapshot.forEach((doc) => {

            arr.push({
              ...doc.val(),
              id: doc.key,
            });

          })

          arr.forEach((val)=>{
            console.log(val.email.toString().toLowerCase())
            console.log(data.get('email').toLowerCase())
            if(val.email.toString().toLowerCase() === data.get('email').toLowerCase()){
              emailFound = true;
              emailRecord = val;
              
              allemails.push(val)
            }
          })
    
  
          if(emailFound)
          {
            
            setEmailError(true)
            setLoading(false)
          }
          else{
            
            const timestamp = Timestamp.fromDate(new Date()).seconds;

            set(dbref(rtdb, `waitinglist/${timestamp}`), {
              email: data.get('email'),
              created: timestamp,
            }).then(()=>{
              setOpen(true)
              setEmailError(false)
              setLoading(false)
            });
          }
    
    
        }
        else{
          const timestamp = Timestamp.fromDate(new Date()).seconds;

          set(dbref(rtdb, `waitinglist/${timestamp}`), {
            email: data.get('email'),
            created: timestamp,
          }).then(()=>{
            setOpen(true)
            setEmailError(false)
            setLoading(false)
          });
        }
      })
      
    }


    

  };


  return (
    <>
      <Helmet>
        <title> Join the waiting List | Avanti Digix </title>
      </Helmet>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Thanks for signing up"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              <Grid style={{ width: "100%", display: "flex",
            flexDirection: "column" }}>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              We'll keep you posted on the latest news.
              </Typography>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>


        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column", alignItems: "center" }}>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, display: "flex", width: "100%", flexDirection: "column", alignItems: "center" }}>
             <Grid>
              <img src='assets/joinwaitinglist.jpg' alt='img'/>
              <Grid style={{ backgroundColor: "#0c1f3c"}}>
                <Typography style={{ fontSize: 12, color: 'white', paddingLeft: 20, marginTop: -1 }}>
                Email Address
                </Typography>
                <TextField
                  style={{ marginTop: 10, width: "100%", color: "white", paddingLeft: 20, paddingRight: 20, borderColor: "white" }}
                  id="email"
                  name="email"
                  required
                  type="email"
                  sx={{ input: { color: 'white', borderColor: "white" } }} 
                  error={emailError}
                  autoComplete="off"
                  variant="outlined"
                />
              </Grid>
              {emailError ? <Typography style={{ marginTop: -1, paddingTop: 10,  paddingLeft: 20,fontSize: 11, color: 'red',backgroundColor: "#0c1f3c" }}>
          Email Incorrect / Email Registered.
          </Typography> : null }
             </Grid>

              



            <Grid style={{marginTop: -1, paddingTop: 10, paddingLeft: 20, paddingRight: 20, width: "100%",backgroundColor: "#0c1f3c" }} >
              {loading ?<CircularProgress sx={{ mt: 1, mb: 1 }} color="inherit" />:
              <Button
                type="submit"
                variant="outlined"
                disableElevation
                style={{ paddingLeft: 20, paddingRight: 20, width: "100%", borderColor: "#374860" }}
                sx={{ mt: 1, mb: 1, color: "white" }}
              >
                 SIGN ME UP
              </Button>}
              
              </Grid>
              
              <img  style={{marginTop: -1 }} src='assets/joinwaitinglist2.jpg' alt='img'/>
             
            </Box>
            
        </Grid>
        <Grid style={{ display: "flex", justifyContent: "center", height: 120 }}>
        <Link href="/home"><img src="/assets/fulllogo.png" alt='logo'  height={120}/> </Link>
          </Grid>
          <Grid style={{ display: "flex", justifyContent: "center", height: 20 }}>
          <img src="/assets/socialmedia.jpg" alt='logo'  height={20}/>
          </Grid>
       
        
      </Grid>
    </>
  );
}
