// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBF2TSzLFudG6zcXXcww4_w-R773324PmU",
  authDomain: "avantidigix.firebaseapp.com",
  databaseURL: "https://avantidigix-default-rtdb.firebaseio.com",
  projectId: "avantidigix",
  storageBucket: "avantidigix.appspot.com",
  messagingSenderId: "633659017175",
  appId: "1:633659017175:web:f6ce68307555e139532d92"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);


export default app;