import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Stack, Typography, Grid, Button } from '@mui/material';
// ----------------------------------------------------------------------

export default function HomePage() {


  return (
    <>
      <Helmet>
        <title> Home | Avanti Digix </title>
      </Helmet>

      <Grid style={{ width: "100%", backgroundColor: 'white', display: "flex", alignItems: "center",  
      flexDirection: "column"  }}>


        <Grid style={{ width: "100%", maxWidth: 1200, display: "flex", flexDirection: "column", 
        alignItems: "center", marginTop: 40 }}>
          <Grid style={{ display: "flex", justifyContent: "center", height: 200 }}>
          <img src="/assets/fulllogo.png" alt='logo'  height={200}/>
          </Grid>
          <Grid container style={{ display: "flex", flexDirection: "row", justifyContent: "center", maxWidth: 1000 }}>
            <Grid item xs={12} lg={6} >
              <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Grid>
                <img src="/assets/launch.png" alt='banner' width={"100%"}/>
                </Grid>
                <Button  
                size="large"
                color="primary"
                target="_top"
                rel="noopener noreferrer"
                href={`mailto:daniel.tan@avantidigix.com`} style={{ display: "flex", flexDirection: "row", justifyContent: "center", height: 40 }}>
                <img src="/assets/emailbtn.png" alt='email button' height={40}/>
                </Button>
                
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: 20 }}>
              <img src="/assets/launch2.png" alt='banner' height={300}/>
              </Grid>
            
            </Grid>
          </Grid>
          <Button href={`/waitlist`} style={{ marginTop: 20 }}>
          <img src="/assets/joinbtn2.png" alt='join button' width={"90%"}/>
          </Button>

        </Grid>

      </Grid>
    </>
  );
}
