import { Outlet } from 'react-router-dom';

import AppAppBar from '../../modules/views/AppAppBar';

import AppFooter from '../../modules/views/AppFooter';

export default function Frontend() {
  return (
    <>
      <Outlet />
      <AppFooter/>
    </>
    
  );
}
