import { Navigate, useRoutes } from 'react-router-dom';

import { Grid } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// layouts
import SimpleLayout from './layouts/simple';
import Frontend from './layouts/frontend';
//

import WaitList from './pages/WaitList';
import Page404 from './pages/Page404';
import HomePage from './pages/HomePage';


import { UserAuth } from './context/AuthContext'
// ----------------------------------------------------------------------

export default function Router() {

  const { user } = UserAuth()

  const routes = useRoutes([
    {
      path: '/',
      element: <Frontend />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'waitlist', element: <WaitList />},
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
